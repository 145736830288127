.gray-row {
  background-color: #f0f0f0 !important;
}

.small-font-table {
  font-size: 11px;
}

.fixed-button {
  position: fixed;
  bottom: 74px;
  right: 44px;
  z-index: 1;
}

.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.71428571;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  left: 280px;
  bottom: 70px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.custom-collapse-small .ant-collapse-header {
  padding: 5px 35px !important;
}

