// takes advantage of Bootstrap 4 variables, however it's not required for essential layout
@import "variables";

@import "layout/layout-antd";
@import "layout/layout-boxed";
@import "layout/layout-fixed-sidenav";
@import "layout/layout-fixed-header";
@import "layout/sidenav-layout-antd";
@import "layout/sidenav";
@import "layout/sidenav-mobile";
@import "layout/sidenav-theme";

@import "layout/logo";
@import "layout/footer";
@import "layout/footer-styles";
@import "layout/header";
@import "layout/header-theme";
@import "layout/content";
@import "layout/content-with-tabs";
@import "layout/quickview";
@import "layout/quickview-customizer";
@import "layout/quickview-customizer-antd";
@import "layout/flex-items";

//
@import "layout/layout-app-v2";

.route{
    text-decoration: none;
    color: white;
}
.route:hover{
    color: white;
}
.ant-form-item-label {
    text-align: left !important;
}
.ant-form-item{
    margin-bottom: 5px !important;
}
.ant-divider-horizontal {
    margin: 12px 0;
}

.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
}

.custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.deleteRow{
    background: #F1F1F1;
}

.leaflet-container {
    height: 100vh;
    width: 100%;
}

.sr-only {
    display: none;
}



.ant-select-disabled .ant-select-arrow{
    display: none;
}

.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
}
